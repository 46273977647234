.sunset-player {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 992px;
  height: 100%;
}

.embed .sunset-player {
  max-width: inherit !important;
  max-height: inherit !important;
}

.wrapper {
  display: inline-flex;
  padding: 20px 24px 28px;
  flex: 1;
}

.showcase {
  display: flex;
  flex-direction: column;
  margin-right: 28px;
}

.player {
  margin-top: 32px;
}

.social {
  display: none;
  align-items: center;
  height: 60px;
  justify-content: center;
}

.social p {
  margin-bottom: 0 !important;
  margin-right: 20px !important;
  opacity: 1 !important;
}

.social a:not(:last-child) {
  margin-right: 20px !important;
}

.play-button,
.showcase .thumbnail {
  min-width: 220px;
  max-width: 220px;
  width: 220px;
  height: 220px;
}

.toggle-popup {
  display: none;
}

@media only screen and (max-width: 991px) {
  .toggle-popup {
    display: flex;
  }

  .sunset-player {
    max-width: 512px;
  }

  .banner {
    background-position: 0 -10px !important;
  }

  .wrapper {
    flex-direction: column;
  }

  .social {
    display: flex;
  }

  .social-banner {
    display: none !important;
  }

  .showcase .thumbnail {
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .play-button,
  .showcase .thumbnail {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .play-button,
  .showcase .thumbnail {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    height: 100px;
  }
}
