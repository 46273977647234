.tracksWrapper {
  display: flex;
  flex-direction: column;
  width: 404px;
}

.tracksWrapper .description {
  margin-left: calc(16px + 64px + 1px + 8px + 12px);
}

.trackWrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 0;
}

.trackWrapper:first-child {
  padding-top: 0;
}

.trackWrapper:last-child {
  padding-bottom: 0;
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.localtime {
  min-width: 64px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0 8px;
}

.separator {
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 8px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.title {
  font-size: 18;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.label {
  font-weight: 400;
  font-style: italic;
}

.secondary {
  margin: 0;
  font-size: 16;
  font-weight: 600;
  line-height: 20px;
}

@media only screen and (max-width: 991px) {
  .tracksWrapper {
    margin-top: 24px;
    width: 100%;
  }
}
