body {
  margin: 0;
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.description {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
  padding: 0;
}

html,
body,
#root,
.embed {
  height: 100%;
}
